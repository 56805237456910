<template>
  <div
    class="bg-grad-gray relative flex flex-col justify-start items-center w-11/12 m-4 p-4 mx-auto shadow-lg my-4 max-w-screen-xl rounded-xl">
    <i
      @click="closeProjectCard"
      class="fas fa-times absolute top-4 left-4 text-3xl cursor-pointer"
    ></i>
    <h2 class="text-2xl mb-12">{{ projectname }}</h2>
    <img
      :src="require(`../assets/${imglogo}`)"
      alt="Logo du projet"
      class="w-auto h-40 object-cover mb-12"
    />
    <div>
      <!-- xl:w-6/12 -->
      <p
        class="  w-auto 2xl:mr-4 mb-6"
        v-html="$t(`projects.desc.${linkdesc}`)"
      ></p>
      <iframe v-if="linkvideo"
        :src="linkvideo"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="w-full lg:w-8/12 max-w-screen-md h-52 md:h-96"
      ></iframe>
    </div>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";

export default {
  name: "opencard",
  props: {
    projectname: {
      type: String,
      required: true,
    },
    imglogo: {
      type: String,
      required: true,
    },
    linkdesc: {
      type: String,
      required: true,
    },
    linkvideo: {
      type: String,
      required: false,
    },
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

     

    return { t };
  },
  methods: {
    closeProjectCard() {
      this.$emit("closeProjectCard");
    },
  },
};
</script>
