<template>
  <button
    class="idprojectcard flex flex-col justify-center items-center w-11/12 h-80 m-4 carte relative md:w-5/12 xl:w-3/12 shadow-2xl lg:shadow-lg hover:shadow-2xl transition-all  rounded-xl focus:transform focus:-translate-y-4 focus:outline-none focus:ring-4 focus:ring-bg-hb dark:focus:ring-[#02D7EE]">
    <h2 class="text-2xl">{{ projectname }}</h2>
    <img
      :src="require(`../assets/${src}`)"
      :alt="alt"
      class="w-11/12 h-60 object-contain"
    />
  </button>
</template>

<script>
export default {
  name: "card",
  props: {
    projectname: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
  },
};
</script>
