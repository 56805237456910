/* eslint-disable */
<template>
  <div id="project" class="dark:bg-gray-900 pb-16 transition duration-300 spawn_animation bg-white">
    <h1 class="text-2xl pt-4 mb-4 text-center dark:text-white">{{ $t("projects.h1") }}</h1>
    <div>
      <Opencard @closeProjectCard="closeProjectCard" v-if="projectOpen == 1" projectname="Skincasts" imglogo="logo_skincasts_noir.png" linkdesc="skincasts" linkvideo="https://www.youtube.com/embed/fDYmao-mOh4" class="bg-grad-SC spawn_animation"/>
      <Opencard @closeProjectCard="closeProjectCard" v-if="projectOpen == 2" projectname="HV2R" imglogo="logo_hv2r.png" linkdesc="hv2r" linkvideo="" class="bg-grad-yellow spawn_animation"/>
      <Opencard @closeProjectCard="closeProjectCard" v-if="projectOpen == 3" projectname="Hyvirea" imglogo="logo_hyvirea.png" linkdesc="hyvirea" linkvideo="https://www.youtube.com/embed/dN5GbeXA8Ww" class="bg-grad-blue spawn_animation"/>
      <Opencard @closeProjectCard="closeProjectCard" v-if="projectOpen == 4" projectname="EscapeWatt" imglogo="logo_EscapeWatt.png" linkdesc="escapewatt" linkvideo="https://www.youtube.com/embed/TRqAtF3YY24" class="spawn_animation"/>
      <Opencard @closeProjectCard="closeProjectCard" v-if="projectOpen == 5" projectname="Inovsys" imglogo="logo_inovsys.png" linkdesc="inovsys" linkvideo="https://www.youtube.com/embed/37j3c1xujJI" class="spawn_animation"/>
      <Opencard @closeProjectCard="closeProjectCard" v-if="projectOpen == 6" projectname="Team Vr" imglogo="logo_teamvr.png" linkdesc="teamvr" linkvideo="" class="spawn_animation"/>
    </div>
    <div class="w-full m-auto flex flex-wrap justify-center max-w-screen-2xl transitiontopY">
      <Card @click="updateProject('1')" v-show="projectOpen != 1" src="logo_skincasts_noir.png" projectname="Skincasts" alt="Skincasts" class="gradientborderSC"/>
      <Card @click="updateProject('2')" v-show="projectOpen != 2" src="logo_hv2r.png" projectname="HV2R" alt="HV2R" class="gradientborderyellow"/>
      <Card @click="updateProject('3')" v-show="projectOpen != 3" src="logo_hyvirea.png" projectname="Hyvirea" alt="Hyvirea" class="gradientborderblue"/>
      <Card @click="updateProject('4')" v-show="projectOpen != 4" src="logo_EscapeWatt.png" projectname="Escape Watt" alt="Escape Watt" class="bg-grad-gray-top dark:hover:bg-white"/>
      <Card @click="updateProject('5')" v-show="projectOpen != 5" src="logo_inovsys.png" projectname="Inovsys" alt="Inovsys" class="bg-grad-gray-top dark:hover:bg-white"/>
      <Card @click="updateProject('6')" v-show="projectOpen != 6" src="logo_teamvr.png" projectname="Team VR" alt="Team Vr" class="bg-grad-gray-top dark:hover:bg-white"/>
    </div>
  </div>
</template>

<script>
import Card from "../components/card.vue";
import { useI18n } from "vue-i18n";
import Opencard from "../components/opencard.vue";

export default {
  data() {
    return {
      projectOpen: 0,
    };
  },
  methods: {
    updateProject(projectid) {
      if (projectid == this.projectOpen) {
        this.projectOpen = 0;
      } else {
        this.projectOpen = projectid;
      }
      window.scroll(0, 115);
    },
    closeProjectCard() {
      this.projectOpen = 0;
    },
  },
  components: {
    Card,
    Opencard,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });
    return { t };
  },
};
</script>